import { PlanCard } from 'components/PlanCard';
import { PlanToggle } from 'components/PlanToggle';
import { Section } from 'components/Section';
import { Title } from 'components/Title';
import { FC } from 'react';
import { SectionPlans } from 'types/Builder';

const colors = ['var(--color-quaternary)', 'var(--color-secondary)', 'var(--color-tertiary)', 'var(--color-primary)'];

export const Plans: FC<SectionPlans> = ({ heading, description, subtitle, decorate, plansContent, backgroundImage, backgroundColor }) => {
  return (
    <Section className="pos:relative pt:pfs(40px,100px)!" backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <div
        className="pos:absolute t:12% l:-8% w:976px h:887px bdrs:976px op:0.08 bgc:color-tertiary fil:blur(379px) z:-1"
        style={{ mixBlendMode: 'multiply' }}
      ></div>
      <div
        className="pos:absolute b:-12% r:-8% w:850px h:773px bdrs:850px op:0.08 fil:blur(368px) z:-1"
        style={{ mixBlendMode: 'multiply', background: 'linear-gradient(141deg, var(--color-primary) -7.7%, var(--color-secondary) 107.88%)' }}
      ></div>
      <div className="container">
        <Title
          title={heading}
          titleClass="fz:pfs(35px,58px) fw:500!"
          subTitle={subtitle}
          text={description}
          textClass="fw:300!"
          decorate={decorate}
        />
        <div className="d:flex fld:column ai:center mt:pfs(40px,61px) mb:pfs(20px,58px) pos:relative">
          <PlanToggle />
        </div>
        <div className="row">
          {plansContent.map((item, index) => {
            return (
              <div key={index} className="col-xs-12 col-sm-6 col-md-3" data-json={JSON.stringify(item)}>
                <PlanCard
                  {...item}
                  color={colors[index]}
                  onMoreClick={() => {
                    const planComparisonEl = document.getElementById('plan-comparison');
                    if (planComparisonEl) {
                      window.scrollTo({
                        top: planComparisonEl.offsetTop,
                        behavior: 'smooth',
                      });
                    }
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};
