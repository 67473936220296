import { FC, useEffect } from 'react';
import { createGlobalState } from 'react-use';
import { pmChildren } from 'utils/postMessage';
import * as styles from './PlanToggle.module.scss';

export type PlanToggleType = 'monthly' | 'yearly';

export interface PlanToggleProps {
  onChange?: (type: PlanToggleType) => void;
}

const usePlanToggleStatePrivate = createGlobalState<PlanToggleType>('monthly');
const useCurrentType = createGlobalState<PlanToggleType | null>(null);

export const usePlanToggleState = () => {
  const [state] = usePlanToggleStatePrivate();
  const [currentType] = useCurrentType();
  return {
    currentType,
    nextType: state ?? 'monthly',
  };
};

export const PlanToggle: FC<PlanToggleProps> = ({ onChange }) => {
  const [type, setPlanToggleState] = usePlanToggleStatePrivate();
  const [currentType, setCurrentType] = useCurrentType();

  useEffect(() => {
    const off = pmChildren.on('@landing/currentPlan', ({ type }) => {
      setPlanToggleState(type);
      if (currentType === null) {
        setCurrentType(type);
      }
      onChange?.(type);
    });
    const off2 = pmChildren.on('@landing/plan/success', ({ type }) => {
      setCurrentType(type);
    });

    return () => {
      off();
      off2();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.toggle} pos:relative p:pfs(5px,10px) d:flex cg:10px ai:center`}>
      <div
        className={`p:8px_20px p:8px@+sm fz:pfs(13px,18px) bdrs:130px cur:pointer us:none ta:center ${
          type !== 'yearly' ? 'bgc:color-dark c:color-light' : 'c:color-gray9'
        }`}
        onClick={() => {
          if (type === 'yearly') {
            onChange?.('monthly');
            setPlanToggleState('monthly');
          }
        }}
      >
        Pay monthly
      </div>
      <div
        className={`p:8px_20px p:8px@+sm fz:pfs(13px,18px) bdrs:130px cur:pointer us:none ta:center ${
          type === 'yearly' ? 'bgc:color-dark c:color-light' : 'c:color-gray9'
        }`}
        onClick={() => {
          if (type !== 'yearly') {
            onChange?.('yearly');
            setPlanToggleState('yearly');
          }
        }}
      >
        Pay yearly (save 17%)
      </div>
    </div>
  );
};
