// extracted by mini-css-extract-plugin
export var body = "PlanCard-module--body--4d0df";
export var btn = "PlanCard-module--btn--b317d";
export var container = "PlanCard-module--container--477db";
export var featureItem = "PlanCard-module--featureItem--f768d";
export var header = "PlanCard-module--header--e28e1";
export var highlight = "PlanCard-module--highlight--aa8fb";
export var icon = "PlanCard-module--icon--682dd";
export var image = "PlanCard-module--image--f0eb4";
export var learnMore = "PlanCard-module--learnMore--22bfe";
export var link = "PlanCard-module--link--4c6f2";
export var price = "PlanCard-module--price--d2f5e";
export var title = "PlanCard-module--title--ec86b";