import { GetStartedPopup } from 'components/GetStartedPopup';
import { Image } from 'components/Image';
import { Section } from 'components/Section';
import { Title } from 'components/Title';
import { FC } from 'react';
import { SectionCTA2 } from 'types/Builder';
import { navigate } from 'gatsby';

export const CTASection2: FC<SectionCTA2> = ({
  heading,
  description,
  subtitle,
  decorate,
  imageCTA,
  buttonText,
  link,
  reverse,
  backgroundImage,
  backgroundColor,
}) => {
  return (
    <Section style={{ position: 'relative' }} backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <div className="container">
        <div className={`row mt:0 d:flex rg:30px ${reverse ? 'fld:row-reverse' : ''}`}>
          <div className="col-xs-12 col-sm-6 mt:0 d:flex fld:column ai:flex-start jc:center">
            <Title
              title={heading}
              titleClass="fz:pfs(40px,55px) mb:30px!"
              text={description}
              textClass="maw:520px fz:20px!"
              decorate={decorate}
              align="left"
            />
            <div className="d:flex ai:center cg:pfs(15px,20px)">
              <GetStartedPopup
                style="style2"
                className="d:flex ai:center p:14px_22px p:10px@+sm fz:pfs(14px,18px) fw:500 cg:7px"
                buttonStyle={{
                  boxShadow: '0px 0px 10px 0px #C4E6FF',
                }}
                hasIcon={true}
                buttonText="Install Veda"
              />
              <div
                className="d:flex p:11px_25px p:10px@+sm fz:pfs(14px,18px) fw:500 bd:1px_solid_color-gray4 bdrs:10px bxsh:0_0_10px_#C4E6FF cur:pointer"
                onClick={() => {
                  if (link.includes('http')) {
                    window.open(link, '_blank');
                  } else {
                    navigate(link);
                  }
                }}
              >
                {buttonText}
              </div>
            </div>
            <div className="mt:35px d:flex cg:15px fz:pfs(16px,20px)">
              <span>
                <i className="fas fa-star c:#FFB31B"></i>
              </span>
              <span className="fw:500 c:color-primary">{subtitle}</span>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 mt:0 d:flex ai:center obf:contain!*img">
            {!!imageCTA && <Image style={{ width: '100%' }} src={imageCTA} alt="Image CTA" />}
          </div>
        </div>
      </div>
    </Section>
  );
};
