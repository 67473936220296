// extracted by mini-css-extract-plugin
export var buttonGroup = "PlanComparison-module--buttonGroup--60a71";
export var features = "PlanComparison-module--features--ee787";
export var featuresContent = "PlanComparison-module--featuresContent--780ae";
export var featuresHeader = "PlanComparison-module--featuresHeader--d5e1c";
export var featuresTitle = "PlanComparison-module--featuresTitle--73ef6";
export var last = "PlanComparison-module--last--1f6e4";
export var plan = "PlanComparison-module--plan--bf754";
export var planBody = "PlanComparison-module--planBody--573ab";
export var planBodyHighlight = "PlanComparison-module--planBodyHighlight--bff02";
export var planHeader = "PlanComparison-module--planHeader--dc2ad";
export var planHeaderHighlight = "PlanComparison-module--planHeaderHighlight--10b56";
export var planPrice = "PlanComparison-module--planPrice--13504";
export var planTitle = "PlanComparison-module--planTitle--c188e";
export var plans = "PlanComparison-module--plans--df8fc";
export var table = "PlanComparison-module--table--77c62";