import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { GetStartedPopup } from 'components/GetStartedPopup';
import { Markdown } from 'components/Markdown/Markdown';
import { usePlanToggleState } from 'components/PlanToggle';
import { PricingPopupSupport, usePricingPopupStatic, usePricingPopupSupport } from 'components/PricingPopupSupport/PricingPopupSupport';
import { Section } from 'components/Section';
import { Title } from 'components/Title';
import { Tooltip } from 'components/Tooltip';
import { FC, useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';
import { SectionPlanComparison, TableItem } from 'types/Builder';
import { pmChildren } from 'utils/postMessage';
import { reactNodeToString } from 'utils/reactNodeToString';
import * as styles from './PlanComparison.module.scss';

const MAX_WIDTH = 950;

export const PlanComparison: FC<SectionPlanComparison> = ({ heading, description, planFeatures, plansTable, backgroundImage, backgroundColor }) => {
  const [features] = planFeatures;
  const [activeTitle, setActiveTitle] = useState(plansTable[0]?.title ?? '');
  const { width } = useWindowSize();
  const featuresContent = features.content.trim();
  const { nextType, currentType } = usePlanToggleState();
  const [idLoading, setIdLoading] = useState('');
  const [isLearnMore, setLearnMore] = useState(false);
  const location = useLocation();
  const [currentPlan, setCurrentPlan] = useState('');
  const { contentRef, getLiProps } = usePricingPopupSupport();
  const popUplist = usePricingPopupStatic();
  let headingIndex = 0;
  useEffect(() => {
    const off1 = pmChildren.on('@landing/plan/success', ({ plan }) => {
      setIdLoading('');
      setCurrentPlan(plan);
    });
    const off2 = pmChildren.on('@landing/plan/failure', () => {
      setIdLoading('');
    });
    const off3 = pmChildren.on('@landing/currentPlan', ({ plan }) => {
      setCurrentPlan(plan);
    });
    return () => {
      off1();
      off2();
      off3();
    };
  }, []);

  const renderTableItem = (item: TableItem, index: number) => {
    const itemWidth = width <= MAX_WIDTH && item.title === activeTitle ? '100%' : `${100 / plansTable.length}%`;
    if (width <= MAX_WIDTH && item.title !== activeTitle) {
      return null;
    }
    const itemContent = item.content.trim();
    let rowIndex = 0;
    return (
      <div key={item.title} className={styles.plan} style={{ width: itemWidth }} data-json={JSON.stringify(item)}>
        <div
          className={classNames(
            styles.planHeader,
            item.highlight ? styles.planHeaderHighlight : '',
            location.pathname === '/pricing-for-veda-builder' ? 't:0!' : '',
          )}
          style={plansTable.length - 1 === index ? { borderRadius: '0 10px 0 0' } : {}}
        >
          <h3 className={styles.planTitle}>{item.title}</h3>
          {/* <div className={styles.planPrice} dangerouslySetInnerHTML={{ __html: nextType === 'monthly' ? item.pricePerMonth : item.pricePerYear }} /> */}
          {currentPlan === item.handle && nextType === currentType && (
            <div className="pos:absolute t:10px r:10px w:30px h:30px bgc:color-secondary c:color-light bdrs:50% d:flex ai:center jc:center fz:16px">
              <i className="far fa-check" />
            </div>
          )}
          {currentPlan === item.handle && nextType === currentType ? (
            <Button
              size="medium"
              style={{ width: '100%', maxWidth: 200, fontSize: '16px', fontWeight: 400, borderRadius: '10px' }}
              backgroundColor="var(--color-gray2)"
              color="var(--color-gray9)"
            >
              Current Plan
            </Button>
          ) : (
            <GetStartedPopup
              buttonSize="medium"
              buttonHighlight={item.highlight}
              buttonText={item.buttonText}
              buttonBackground={item.highlight ? 'var(--color-primary)' : 'transparent'}
              buttonStyle={{
                width: '100%',
                maxWidth: 200,
                color: `${item.highlight ? 'var(--color-light)' : 'var(--color-primary)'}`,
                border: '1px solid var(--color-primary)',
                fontSize: '16px',
                fontWeight: 400,
                borderRadius: '10px',
              }}
              isLoading={idLoading === item.handle}
              onClickForBuilder={() => {
                if (!idLoading) {
                  pmChildren.emit('@landing/plan/request', {
                    handle: item.handle,
                    type: nextType,
                    pricePerMonth: item.pricePerMonth,
                    pricePerYear: item.pricePerYear,
                    title: item.handle,
                  });
                  setIdLoading(item.handle);
                }
              }}
            />
          )}
        </div>
        <div className={classNames(styles.planBody, item.highlight ? styles.planBodyHighlight : '')}>
          <Markdown
            components={{
              li: ({ node: _, ...props }) => {
                rowIndex += 1;
                const liText = reactNodeToString(props.children).trim();
                const textLast = liText.includes('[last]');
                if (rowIndex > 11 && !isLearnMore) {
                  return <></>;
                }
                if (/^\d+.\s/g.test(liText)) {
                  const text = liText.replace(/^\d+.\s/g, '').replace('[last]', '');
                  return <li className={textLast ? `${styles.last} mih:80px` : 'mih:80px'}>{text}</li>;
                }
                if (liText.replace('[last]', '') === 'no') {
                  return (
                    <li className={textLast ? styles.last : ''}>
                      <i className="far fa-minus" />
                    </li>
                  );
                }
                if (liText.replace('[last]', '') === 'yes') {
                  return (
                    <li className={textLast ? styles.last : ''}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                        <path
                          d="M2 9.97245L6.63776 14.7551C8.82143 8.48571 10.6378 5.73469 14.7551 2"
                          stroke="var(--color-quaternary)"
                          strokeWidth="2.6"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </li>
                  );
                }
                if (textLast) {
                  const text = liText.replace('[last]', '');
                  return (
                    <li {...props} className={styles.last}>
                      {text}
                    </li>
                  );
                }
                return <li {...props} />;
              },
            }}
          >
            {!!itemContent ? `${itemContent}[last]` : itemContent}
          </Markdown>
        </div>
      </div>
    );
  };

  return (
    <Section className="p:50px_0_75px_0!" id="plan-comparison" backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <div className="container">
        <Title align="left" title={heading} titleClass="fz:pfs(35px,40px)" text={description} />
        {width <= MAX_WIDTH && (
          <div className={styles.buttonGroup}>
            {plansTable.map(item => {
              return (
                <Button
                  key={item.title}
                  onClick={() => setActiveTitle(item.title)}
                  backgroundColor={item.title === activeTitle ? 'var(--color-primary)' : 'transparent'}
                  color={item.title === activeTitle ? 'var(--color-light)' : 'var(--color-primary)'}
                >
                  {item.title}
                </Button>
              );
            })}
          </div>
        )}
        <div className={styles.table}>
          <div className={styles.features} style={{ width: width <= MAX_WIDTH ? '55%' : '30%' }}>
            <div className={classNames(styles.featuresHeader, location.pathname === '/pricing-for-veda-builder' ? 't:0!' : '')}>
              <h3 className={styles.featuresTitle}>{features.title}</h3>
            </div>
            <div ref={contentRef} className={styles.featuresContent}>
              <Markdown
                components={{
                  li: ({ node: _, ...props }) => {
                    headingIndex += 1;
                    const liText = reactNodeToString(props.children).trim();
                    const isPopUp = (txt: string) => {
                      return (
                        typeof txt === 'string' &&
                        popUplist.some(
                          popup =>
                            popup.listTitle.replace(/﻿/g, '').trim() ===
                            txt
                              .replace(/﻿/g, '')
                              .replace(/\[last\]/g, '')
                              .trim(),
                        )
                      );
                    };
                    if (headingIndex > 11 && !isLearnMore) {
                      return <></>;
                    }
                    if (/^\d+.\s/g.test(liText)) {
                      const text = liText.replace(/^\d+.\s/g, '').replace('[last]', '');
                      return (
                        <li {...getLiProps(props)}>
                          {text}
                          {isPopUp(text ?? '') ? (
                            <span className="pos:relative ml:7px w:15px">
                              <svg
                                className="pos:absolute t:50% l:0 trf:translateY(-50%)"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="15"
                                viewBox="0 0 14 15"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_1614_39431)">
                                  <mask
                                    id="mask0_1614_39431"
                                    style={{ maskType: 'luminance' }}
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="14"
                                    height="15"
                                  >
                                    <path d="M14 0.5H0V14.5H14V0.5Z" fill="white" />
                                  </mask>
                                  <g mask="url(#mask0_1614_39431)">
                                    <path
                                      d="M7 13.625C5.37555 13.625 3.81763 12.9797 2.66897 11.831C1.52031 10.6824 0.875 9.12445 0.875 7.5C0.875 5.87555 1.52031 4.31763 2.66897 3.16897C3.81763 2.02031 5.37555 1.375 7 1.375C8.62445 1.375 10.1824 2.02031 11.331 3.16897C12.4797 4.31763 13.125 5.87555 13.125 7.5C13.125 9.12445 12.4797 10.6824 11.331 11.831C10.1824 12.9797 8.62445 13.625 7 13.625ZM7 14.5C8.85651 14.5 10.637 13.7625 11.9497 12.4497C13.2625 11.137 14 9.35651 14 7.5C14 5.64348 13.2625 3.86301 11.9497 2.55025C10.637 1.2375 8.85651 0.5 7 0.5C5.14348 0.5 3.36301 1.2375 2.05025 2.55025C0.737498 3.86301 0 5.64348 0 7.5C0 9.35651 0.737498 11.137 2.05025 12.4497C3.36301 13.7625 5.14348 14.5 7 14.5Z"
                                      fill="var(--color-primary)"
                                    />
                                    <path
                                      d="M7.81371 6.2645L5.80996 6.51562L5.73821 6.84812L6.13196 6.92075C6.38921 6.982 6.43996 7.07475 6.38396 7.33113L5.73821 10.3656C5.56846 11.1505 5.83008 11.5197 6.44521 11.5197C6.92208 11.5197 7.47596 11.2992 7.72709 10.9965L7.80408 10.6325C7.62908 10.7865 7.37358 10.8477 7.20383 10.8477C6.96321 10.8477 6.87571 10.6789 6.93783 10.3814L7.81371 6.2645ZM7.87496 4.4375C7.87496 4.66956 7.78277 4.89212 7.61868 5.05622C7.45458 5.22031 7.23202 5.3125 6.99996 5.3125C6.7679 5.3125 6.54534 5.22031 6.38124 5.05622C6.21715 4.89212 6.12496 4.66956 6.12496 4.4375C6.12496 4.20544 6.21715 3.98288 6.38124 3.81878C6.54534 3.65469 6.7679 3.5625 6.99996 3.5625C7.23202 3.5625 7.45458 3.65469 7.61868 3.81878C7.78277 3.98288 7.87496 4.20544 7.87496 4.4375Z"
                                      fill="var(--color-primary)"
                                    />
                                  </g>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1614_39431">
                                    <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          ) : (
                            ''
                          )}
                        </li>
                      );
                    }
                    if (/\(|\)/g.test(liText)) {
                      const text = liText.replace(/\(.*([\s\S]*?).*\)/g, '').replace('[last]', '');
                      const tooltip =
                        props.children?.[0]
                          ?.toString()
                          ?.replace(/.*\(/g, '')
                          ?.replace(/\).*/g, '')
                          ?.replace(/\(.*\)/g, '') ?? '';
                      return (
                        <li {...getLiProps(props)}>
                          {text}
                          <Tooltip title={tooltip}>
                            <span className={`pos:relative ml:7px w:15px`}>
                              <svg
                                className="pos:absolute t:50% l:0 trf:translateY(-50%)"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="15"
                                viewBox="0 0 14 15"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_1614_39431)">
                                  <mask
                                    id="mask0_1614_39431"
                                    style={{ maskType: 'luminance' }}
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="14"
                                    height="15"
                                  >
                                    <path d="M14 0.5H0V14.5H14V0.5Z" fill="white" />
                                  </mask>
                                  <g mask="url(#mask0_1614_39431)">
                                    <path
                                      d="M7 13.625C5.37555 13.625 3.81763 12.9797 2.66897 11.831C1.52031 10.6824 0.875 9.12445 0.875 7.5C0.875 5.87555 1.52031 4.31763 2.66897 3.16897C3.81763 2.02031 5.37555 1.375 7 1.375C8.62445 1.375 10.1824 2.02031 11.331 3.16897C12.4797 4.31763 13.125 5.87555 13.125 7.5C13.125 9.12445 12.4797 10.6824 11.331 11.831C10.1824 12.9797 8.62445 13.625 7 13.625ZM7 14.5C8.85651 14.5 10.637 13.7625 11.9497 12.4497C13.2625 11.137 14 9.35651 14 7.5C14 5.64348 13.2625 3.86301 11.9497 2.55025C10.637 1.2375 8.85651 0.5 7 0.5C5.14348 0.5 3.36301 1.2375 2.05025 2.55025C0.737498 3.86301 0 5.64348 0 7.5C0 9.35651 0.737498 11.137 2.05025 12.4497C3.36301 13.7625 5.14348 14.5 7 14.5Z"
                                      fill="var(--color-primary)"
                                    />
                                    <path
                                      d="M7.81371 6.2645L5.80996 6.51562L5.73821 6.84812L6.13196 6.92075C6.38921 6.982 6.43996 7.07475 6.38396 7.33113L5.73821 10.3656C5.56846 11.1505 5.83008 11.5197 6.44521 11.5197C6.92208 11.5197 7.47596 11.2992 7.72709 10.9965L7.80408 10.6325C7.62908 10.7865 7.37358 10.8477 7.20383 10.8477C6.96321 10.8477 6.87571 10.6789 6.93783 10.3814L7.81371 6.2645ZM7.87496 4.4375C7.87496 4.66956 7.78277 4.89212 7.61868 5.05622C7.45458 5.22031 7.23202 5.3125 6.99996 5.3125C6.7679 5.3125 6.54534 5.22031 6.38124 5.05622C6.21715 4.89212 6.12496 4.66956 6.12496 4.4375C6.12496 4.20544 6.21715 3.98288 6.38124 3.81878C6.54534 3.65469 6.7679 3.5625 6.99996 3.5625C7.23202 3.5625 7.45458 3.65469 7.61868 3.81878C7.78277 3.98288 7.87496 4.20544 7.87496 4.4375Z"
                                      fill="var(--color-primary)"
                                    />
                                  </g>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1614_39431">
                                    <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </Tooltip>
                        </li>
                      );
                    }
                    if (liText.includes('[last]')) {
                      const text = liText.replace('[last]', '');
                      return (
                        <li {...getLiProps({ ...props, className: styles.last })}>
                          {text}
                          {isPopUp(text) ? (
                            <span className="pos:relative ml:7px w:15px">
                              <svg
                                className="pos:absolute t:50% l:0 trf:translateY(-50%)"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="15"
                                viewBox="0 0 14 15"
                                fill="none"
                              >
                                <g clipPath="url(#clip0_1614_39431)">
                                  <mask
                                    id="mask0_1614_39431"
                                    style={{ maskType: 'luminance' }}
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="14"
                                    height="15"
                                  >
                                    <path d="M14 0.5H0V14.5H14V0.5Z" fill="white" />
                                  </mask>
                                  <g mask="url(#mask0_1614_39431)">
                                    <path
                                      d="M7 13.625C5.37555 13.625 3.81763 12.9797 2.66897 11.831C1.52031 10.6824 0.875 9.12445 0.875 7.5C0.875 5.87555 1.52031 4.31763 2.66897 3.16897C3.81763 2.02031 5.37555 1.375 7 1.375C8.62445 1.375 10.1824 2.02031 11.331 3.16897C12.4797 4.31763 13.125 5.87555 13.125 7.5C13.125 9.12445 12.4797 10.6824 11.331 11.831C10.1824 12.9797 8.62445 13.625 7 13.625ZM7 14.5C8.85651 14.5 10.637 13.7625 11.9497 12.4497C13.2625 11.137 14 9.35651 14 7.5C14 5.64348 13.2625 3.86301 11.9497 2.55025C10.637 1.2375 8.85651 0.5 7 0.5C5.14348 0.5 3.36301 1.2375 2.05025 2.55025C0.737498 3.86301 0 5.64348 0 7.5C0 9.35651 0.737498 11.137 2.05025 12.4497C3.36301 13.7625 5.14348 14.5 7 14.5Z"
                                      fill="var(--color-primary)"
                                    />
                                    <path
                                      d="M7.81371 6.2645L5.80996 6.51562L5.73821 6.84812L6.13196 6.92075C6.38921 6.982 6.43996 7.07475 6.38396 7.33113L5.73821 10.3656C5.56846 11.1505 5.83008 11.5197 6.44521 11.5197C6.92208 11.5197 7.47596 11.2992 7.72709 10.9965L7.80408 10.6325C7.62908 10.7865 7.37358 10.8477 7.20383 10.8477C6.96321 10.8477 6.87571 10.6789 6.93783 10.3814L7.81371 6.2645ZM7.87496 4.4375C7.87496 4.66956 7.78277 4.89212 7.61868 5.05622C7.45458 5.22031 7.23202 5.3125 6.99996 5.3125C6.7679 5.3125 6.54534 5.22031 6.38124 5.05622C6.21715 4.89212 6.12496 4.66956 6.12496 4.4375C6.12496 4.20544 6.21715 3.98288 6.38124 3.81878C6.54534 3.65469 6.7679 3.5625 6.99996 3.5625C7.23202 3.5625 7.45458 3.65469 7.61868 3.81878C7.78277 3.98288 7.87496 4.20544 7.87496 4.4375Z"
                                      fill="var(--color-primary)"
                                    />
                                  </g>
                                </g>
                                <defs>
                                  <clipPath id="clip0_1614_39431">
                                    <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          ) : (
                            ''
                          )}
                        </li>
                      );
                    }
                    const text = Array.isArray(props.children) ? (props.children[0] as string) : '';
                    return (
                      <li {...getLiProps(props)}>
                        {text}
                        {isPopUp(text ?? '') ? (
                          <span className="pos:relative ml:7px w:15px">
                            <svg
                              className="pos:absolute t:50% l:0 trf:translateY(-50%)"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="15"
                              viewBox="0 0 14 15"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_1614_39431)">
                                <mask
                                  id="mask0_1614_39431"
                                  style={{ maskType: 'luminance' }}
                                  maskUnits="userSpaceOnUse"
                                  x="0"
                                  y="0"
                                  width="14"
                                  height="15"
                                >
                                  <path d="M14 0.5H0V14.5H14V0.5Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_1614_39431)">
                                  <path
                                    d="M7 13.625C5.37555 13.625 3.81763 12.9797 2.66897 11.831C1.52031 10.6824 0.875 9.12445 0.875 7.5C0.875 5.87555 1.52031 4.31763 2.66897 3.16897C3.81763 2.02031 5.37555 1.375 7 1.375C8.62445 1.375 10.1824 2.02031 11.331 3.16897C12.4797 4.31763 13.125 5.87555 13.125 7.5C13.125 9.12445 12.4797 10.6824 11.331 11.831C10.1824 12.9797 8.62445 13.625 7 13.625ZM7 14.5C8.85651 14.5 10.637 13.7625 11.9497 12.4497C13.2625 11.137 14 9.35651 14 7.5C14 5.64348 13.2625 3.86301 11.9497 2.55025C10.637 1.2375 8.85651 0.5 7 0.5C5.14348 0.5 3.36301 1.2375 2.05025 2.55025C0.737498 3.86301 0 5.64348 0 7.5C0 9.35651 0.737498 11.137 2.05025 12.4497C3.36301 13.7625 5.14348 14.5 7 14.5Z"
                                    fill="var(--color-primary)"
                                  />
                                  <path
                                    d="M7.81371 6.2645L5.80996 6.51562L5.73821 6.84812L6.13196 6.92075C6.38921 6.982 6.43996 7.07475 6.38396 7.33113L5.73821 10.3656C5.56846 11.1505 5.83008 11.5197 6.44521 11.5197C6.92208 11.5197 7.47596 11.2992 7.72709 10.9965L7.80408 10.6325C7.62908 10.7865 7.37358 10.8477 7.20383 10.8477C6.96321 10.8477 6.87571 10.6789 6.93783 10.3814L7.81371 6.2645ZM7.87496 4.4375C7.87496 4.66956 7.78277 4.89212 7.61868 5.05622C7.45458 5.22031 7.23202 5.3125 6.99996 5.3125C6.7679 5.3125 6.54534 5.22031 6.38124 5.05622C6.21715 4.89212 6.12496 4.66956 6.12496 4.4375C6.12496 4.20544 6.21715 3.98288 6.38124 3.81878C6.54534 3.65469 6.7679 3.5625 6.99996 3.5625C7.23202 3.5625 7.45458 3.65469 7.61868 3.81878C7.78277 3.98288 7.87496 4.20544 7.87496 4.4375Z"
                                    fill="var(--color-primary)"
                                  />
                                </g>
                              </g>
                              <defs>
                                <clipPath id="clip0_1614_39431">
                                  <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                                </clipPath>
                              </defs>
                            </svg>
                          </span>
                        ) : (
                          ''
                        )}
                      </li>
                    );
                  },
                }}
              >
                {!!featuresContent ? `${featuresContent}[last]` : featuresContent}
              </Markdown>
            </div>
          </div>
          <div className={styles.plans} style={{ width: width <= MAX_WIDTH ? '45%' : '70%' }}>
            {plansTable.map(renderTableItem)}
          </div>
        </div>
        <div className={isLearnMore ? 'd:none' : 'mt:pfs(30px,50px) d:flex jc:center'}>
          <div className="d:flex ai:center cg:18px c:color-primary cur:pointer" onClick={() => setLearnMore(true)}>
            <span className="fz:pfs(16px,25px)">Learn More</span>
            <span>
              <i className="far fa-arrow-down fz:20px"></i>
            </span>
          </div>
        </div>
      </div>
      <PricingPopupSupport />
    </Section>
  );
};
