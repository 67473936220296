import { CompareBuilderCard } from 'components/CompareBuilderCard';
import { Section } from 'components/Section';
import { Title } from 'components/Title';
import { navigate } from 'gatsby';
import { FC } from 'react';
import { ISectionCompareBuilder } from 'types/Builder';

export const CompareBuilder: FC<ISectionCompareBuilder> = ({ heading, description, compareBuilderContent, backgroundColor, backgroundImage }) => {
  return (
    <Section backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <div className="container">
        <div className="p:110px_20px p:30px_20px@+sm bgc:#2B1C50 bdrs:pfs(40px,69px)">
          <div className="d:flex jc:center">
            <div className="maw:1000px">
              <Title
                title={heading}
                titleClass="fz:pfs(35px,58px) fw:500! c:color-gray1! lh:1.2"
                text={description}
                textClass="maw:750px m:auto c:#D1D1F7! d:none@+sm!"
              />
            </div>
          </div>
          <div className="row mt:0!">
            {compareBuilderContent.map((item, index) => {
              return (
                <div key={index} className="col-xs-12 col-sm-6 col-md-6 mt:pfs(20px,50px)">
                  <CompareBuilderCard
                    index={index}
                    title={item.title}
                    description={item.description}
                    buttonText={item.buttonText}
                    onButtonClick={() => {
                      if (item.link.includes('http')) {
                        window.location.href = item.link;
                      } else {
                        navigate(item.link);
                      }
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
};
