import { GetStartedPopup } from 'components/GetStartedPopup';
import { Image } from 'components/Image';
import { Section } from 'components/Section';
import { Title } from 'components/Title';
import { FC } from 'react';
import { SectionCTA } from 'types/Builder';
import * as styles from './CTASection.module.scss';

export const CTASection: FC<SectionCTA> = ({
  heading,
  description,
  subtitle,
  decorate,
  imageCTA,
  reverse,
  buttonText,
  highlight,
  backgroundImage,
  backgroundColor,
}) => {
  return (
    <Section className={`pos:relative ${highlight ? 'pb:80px!' : ''}`} backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <div className="container">
        <div className={highlight ? `${styles.contentHighLight} p:20px@+sm!` : ''}>
          <div className={`row mt:0 d:flex ${highlight ? 'rg:20px@sm' : 'rg:20px'} ${reverse ? '' : 'fld:row-reverse'}`}>
            <div className={`col-xs-12 col-sm-6 ${highlight ? 'col-md-4' : 'col-md-6'} mt:0 d:flex ai:center`}>
              {!!imageCTA && <Image className={highlight ? 'd:none@+sm!' : ''} style={{ width: '100%' }} src={imageCTA} alt="Image CTA" />}
            </div>
            <div className={`col-xs-12 col-sm-6 ${highlight ? 'col-md-8' : 'col-md-6'} mt:0 d:flex fld:column ai:flex-start jc:center`}>
              <Title
                title={heading}
                titleClass={highlight ? 'fz:pfs(35px,58px)' : 'fz:pfs(35px,48px) fw:500! maw:553px'}
                subTitle={subtitle}
                subTitleClass="fz:18px fw:500!"
                text={description}
                textClass={highlight ? 'maw:660px' : 'maw:553px'}
                decorate={decorate}
                align="left"
              />
              <div className="d:flex mt:20px@sm">
                {highlight ? (
                  <div
                    className="pos:relative p:20px_84px p:10px_15px@+sm m:auto w:fit-content fz:pfs(16px,25px) fw:700 c:color-light bdrs:10px bgc:color-primary cur:pointer ov:hidden"
                    onClick={() => {
                      window.open('https://shopify.pxf.io/nL2Voa', '_blank');
                    }}
                  >
                    {buttonText}
                  </div>
                ) : (
                  <GetStartedPopup
                    style="style2"
                    className="p:25px_88px p:16px@+sm"
                    buttonStyle={{
                      fontSize: '20px',
                      fontWeight: 700,
                      fontFamily: 'var(--font-tertiary)',
                    }}
                    buttonText={buttonText}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
