import { FC } from 'react';
import * as styles from './ReviewCard.module.scss';

export interface ReviewCardProps {
  name: string;
  description: string;
  rating: string;
  content: string;
}

export const ReviewCard: FC<ReviewCardProps> = ({ name, description, rating, content }) => {
  return (
    <div className={styles.container}>
      <div className={styles.rating}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
          <path
            d="M7.33426 1.04894C7.54381 0.404019 8.45619 0.404018 8.66574 1.04894L9.86347 4.73516C9.95718 5.02357 10.2259 5.21885 10.5292 5.21885H14.4051C15.0832 5.21885 15.3652 6.08658 14.8166 6.48516L11.6809 8.76337C11.4355 8.94162 11.3329 9.25758 11.4266 9.54599L12.6243 13.2322C12.8339 13.8771 12.0957 14.4134 11.5471 14.0148L8.41145 11.7366C8.16611 11.5584 7.83389 11.5584 7.58855 11.7366L4.45286 14.0148C3.90426 14.4134 3.16613 13.8771 3.37567 13.2322L4.5734 9.54599C4.66711 9.25758 4.56445 8.94162 4.31911 8.76337L1.18342 6.48516C0.634822 6.08658 0.916764 5.21885 1.59487 5.21885H5.47079C5.77405 5.21885 6.04282 5.02357 6.13653 4.73516L7.33426 1.04894Z"
            fill="#FFC700"
          />
        </svg>
        {Number(rating) > 1 ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path
              d="M7.33426 1.04894C7.54381 0.404019 8.45619 0.404018 8.66574 1.04894L9.86347 4.73516C9.95718 5.02357 10.2259 5.21885 10.5292 5.21885H14.4051C15.0832 5.21885 15.3652 6.08658 14.8166 6.48516L11.6809 8.76337C11.4355 8.94162 11.3329 9.25758 11.4266 9.54599L12.6243 13.2322C12.8339 13.8771 12.0957 14.4134 11.5471 14.0148L8.41145 11.7366C8.16611 11.5584 7.83389 11.5584 7.58855 11.7366L4.45286 14.0148C3.90426 14.4134 3.16613 13.8771 3.37567 13.2322L4.5734 9.54599C4.66711 9.25758 4.56445 8.94162 4.31911 8.76337L1.18342 6.48516C0.634822 6.08658 0.916764 5.21885 1.59487 5.21885H5.47079C5.77405 5.21885 6.04282 5.02357 6.13653 4.73516L7.33426 1.04894Z"
              fill="#FFC700"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path
              d="M7.33426 1.04894C7.54381 0.404019 8.45619 0.404018 8.66574 1.04894L9.86347 4.73516C9.95718 5.02357 10.2259 5.21885 10.5292 5.21885H14.4051C15.0832 5.21885 15.3652 6.08658 14.8166 6.48516L11.6809 8.76337C11.4355 8.94162 11.3329 9.25758 11.4266 9.54599L12.6243 13.2322C12.8339 13.8771 12.0957 14.4134 11.5471 14.0148L8.41145 11.7366C8.16611 11.5584 7.83389 11.5584 7.58855 11.7366L4.45286 14.0148C3.90426 14.4134 3.16613 13.8771 3.37567 13.2322L4.5734 9.54599C4.66711 9.25758 4.56445 8.94162 4.31911 8.76337L1.18342 6.48516C0.634822 6.08658 0.916764 5.21885 1.59487 5.21885H5.47079C5.77405 5.21885 6.04282 5.02357 6.13653 4.73516L7.33426 1.04894Z"
              fill="var(--color-gray3)"
            />
          </svg>
        )}
        {Number(rating) > 2 ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path
              d="M7.33426 1.04894C7.54381 0.404019 8.45619 0.404018 8.66574 1.04894L9.86347 4.73516C9.95718 5.02357 10.2259 5.21885 10.5292 5.21885H14.4051C15.0832 5.21885 15.3652 6.08658 14.8166 6.48516L11.6809 8.76337C11.4355 8.94162 11.3329 9.25758 11.4266 9.54599L12.6243 13.2322C12.8339 13.8771 12.0957 14.4134 11.5471 14.0148L8.41145 11.7366C8.16611 11.5584 7.83389 11.5584 7.58855 11.7366L4.45286 14.0148C3.90426 14.4134 3.16613 13.8771 3.37567 13.2322L4.5734 9.54599C4.66711 9.25758 4.56445 8.94162 4.31911 8.76337L1.18342 6.48516C0.634822 6.08658 0.916764 5.21885 1.59487 5.21885H5.47079C5.77405 5.21885 6.04282 5.02357 6.13653 4.73516L7.33426 1.04894Z"
              fill="#FFC700"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path
              d="M7.33426 1.04894C7.54381 0.404019 8.45619 0.404018 8.66574 1.04894L9.86347 4.73516C9.95718 5.02357 10.2259 5.21885 10.5292 5.21885H14.4051C15.0832 5.21885 15.3652 6.08658 14.8166 6.48516L11.6809 8.76337C11.4355 8.94162 11.3329 9.25758 11.4266 9.54599L12.6243 13.2322C12.8339 13.8771 12.0957 14.4134 11.5471 14.0148L8.41145 11.7366C8.16611 11.5584 7.83389 11.5584 7.58855 11.7366L4.45286 14.0148C3.90426 14.4134 3.16613 13.8771 3.37567 13.2322L4.5734 9.54599C4.66711 9.25758 4.56445 8.94162 4.31911 8.76337L1.18342 6.48516C0.634822 6.08658 0.916764 5.21885 1.59487 5.21885H5.47079C5.77405 5.21885 6.04282 5.02357 6.13653 4.73516L7.33426 1.04894Z"
              fill="var(--color-gray3)"
            />
          </svg>
        )}
        {Number(rating) > 3 ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path
              d="M7.33426 1.04894C7.54381 0.404019 8.45619 0.404018 8.66574 1.04894L9.86347 4.73516C9.95718 5.02357 10.2259 5.21885 10.5292 5.21885H14.4051C15.0832 5.21885 15.3652 6.08658 14.8166 6.48516L11.6809 8.76337C11.4355 8.94162 11.3329 9.25758 11.4266 9.54599L12.6243 13.2322C12.8339 13.8771 12.0957 14.4134 11.5471 14.0148L8.41145 11.7366C8.16611 11.5584 7.83389 11.5584 7.58855 11.7366L4.45286 14.0148C3.90426 14.4134 3.16613 13.8771 3.37567 13.2322L4.5734 9.54599C4.66711 9.25758 4.56445 8.94162 4.31911 8.76337L1.18342 6.48516C0.634822 6.08658 0.916764 5.21885 1.59487 5.21885H5.47079C5.77405 5.21885 6.04282 5.02357 6.13653 4.73516L7.33426 1.04894Z"
              fill="#FFC700"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path
              d="M7.33426 1.04894C7.54381 0.404019 8.45619 0.404018 8.66574 1.04894L9.86347 4.73516C9.95718 5.02357 10.2259 5.21885 10.5292 5.21885H14.4051C15.0832 5.21885 15.3652 6.08658 14.8166 6.48516L11.6809 8.76337C11.4355 8.94162 11.3329 9.25758 11.4266 9.54599L12.6243 13.2322C12.8339 13.8771 12.0957 14.4134 11.5471 14.0148L8.41145 11.7366C8.16611 11.5584 7.83389 11.5584 7.58855 11.7366L4.45286 14.0148C3.90426 14.4134 3.16613 13.8771 3.37567 13.2322L4.5734 9.54599C4.66711 9.25758 4.56445 8.94162 4.31911 8.76337L1.18342 6.48516C0.634822 6.08658 0.916764 5.21885 1.59487 5.21885H5.47079C5.77405 5.21885 6.04282 5.02357 6.13653 4.73516L7.33426 1.04894Z"
              fill="var(--color-gray3)"
            />
          </svg>
        )}
        {Number(rating) > 4 ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path
              d="M7.33426 1.04894C7.54381 0.404019 8.45619 0.404018 8.66574 1.04894L9.86347 4.73516C9.95718 5.02357 10.2259 5.21885 10.5292 5.21885H14.4051C15.0832 5.21885 15.3652 6.08658 14.8166 6.48516L11.6809 8.76337C11.4355 8.94162 11.3329 9.25758 11.4266 9.54599L12.6243 13.2322C12.8339 13.8771 12.0957 14.4134 11.5471 14.0148L8.41145 11.7366C8.16611 11.5584 7.83389 11.5584 7.58855 11.7366L4.45286 14.0148C3.90426 14.4134 3.16613 13.8771 3.37567 13.2322L4.5734 9.54599C4.66711 9.25758 4.56445 8.94162 4.31911 8.76337L1.18342 6.48516C0.634822 6.08658 0.916764 5.21885 1.59487 5.21885H5.47079C5.77405 5.21885 6.04282 5.02357 6.13653 4.73516L7.33426 1.04894Z"
              fill="#FFC700"
            />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
            <path
              d="M7.33426 1.04894C7.54381 0.404019 8.45619 0.404018 8.66574 1.04894L9.86347 4.73516C9.95718 5.02357 10.2259 5.21885 10.5292 5.21885H14.4051C15.0832 5.21885 15.3652 6.08658 14.8166 6.48516L11.6809 8.76337C11.4355 8.94162 11.3329 9.25758 11.4266 9.54599L12.6243 13.2322C12.8339 13.8771 12.0957 14.4134 11.5471 14.0148L8.41145 11.7366C8.16611 11.5584 7.83389 11.5584 7.58855 11.7366L4.45286 14.0148C3.90426 14.4134 3.16613 13.8771 3.37567 13.2322L4.5734 9.54599C4.66711 9.25758 4.56445 8.94162 4.31911 8.76337L1.18342 6.48516C0.634822 6.08658 0.916764 5.21885 1.59487 5.21885H5.47079C5.77405 5.21885 6.04282 5.02357 6.13653 4.73516L7.33426 1.04894Z"
              fill="var(--color-gray3)"
            />
          </svg>
        )}
      </div>
      <div className={styles.name}>{name}</div>
      <div className={styles.description}>{description}</div>
      <div className={styles.content}>{content}</div>
    </div>
  );
};
