import { Layout } from 'components/Layout';
import { graphql } from 'gatsby';
import { FC } from 'react';
import { BuilderPage } from 'types/Builder';
import { BuilderPageTemplate } from './BuilderPage';

const IndexPage: FC<BuilderPage> = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <Layout>{<BuilderPageTemplate {...frontmatter} />}</Layout>;
};

export default IndexPage;

export const pageQuery = graphql`
  query BuilderPageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        sections {
          type
          heading
          sectionName
          subtitle
          description
          decorate
          enable
          backgroundColor
          backgroundImage {
            childImageSharp {
              gatsbyImageData(width: 1500, quality: 100, layout: CONSTRAINED)
            }
          }
          # Hero
          heroImage {
            childImageSharp {
              gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
            }
          }
          # CTA
          highlight
          imageCTA {
            childImageSharp {
              gatsbyImageData(width: 900, quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
          reverse
          # Rich Text
          richtextContent
          # Features Grid
          featuresGridContent {
            image {
              childImageSharp {
                gatsbyImageData(width: 300, quality: 100, layout: CONSTRAINED)
              }
            }
            title
            description
            buttonText
            link
          }
          # Compare Builder Content
          compareBuilderContent {
            title
            description
            buttonText
            link
          }
          # Supports
          supportsContent {
            image {
              childImageSharp {
                gatsbyImageData(width: 180, quality: 100, layout: CONSTRAINED)
              }
            }
            title
            description
            buttonText
            link
          }
          # Features
          featuresContent {
            image {
              childImageSharp {
                gatsbyImageData(width: 120, quality: 100, layout: CONSTRAINED)
              }
            }
            title
            description
          }
          # Zigzag
          zigzagContent {
            title
            description
            image
            reverse
          }
          # Plans
          plansContent {
            title
            description
            handle
            pricePerMonth
            pricePerYear
            highlight
            buttonText
            body
          }
          # Collapse
          collapseContent {
            title
            content
          }
          # Plan comparison
          planFeatures {
            title
            content
          }
          plansTable {
            title
            handle
            pricePerMonth
            pricePerYear
            highlight
            buttonText
            content
          }
          # Cost comparison
          costHeader {
            col1
            col2
          }
          costBody {
            groupHeading
            heading
            contentCol1
            contentCol2
          }
          # Contact form
          contactFormContent {
            nameLabel
            emailLabel
            websiteLabel
            optionsLabel
            options {
              value
            }
            messageLabel
            buttonText
          }
          # Reviews
          reviewContent {
            name
            description
            rating
            content
          }
          # CTA 2
          buttonText
          link
          # Plans Comparison 1
          plansHeader {
            title
            handle
            pricePerMonth
            pricePerYear
            highlight
            buttonText
          }
          plansBody {
            groupHeading
            heading
            contentCol1
            contentCol2
            contentCol3
            contentCol4
          }
        }
      }
    }
  }
`;
