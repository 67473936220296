import { Markdown } from 'components/Markdown/Markdown';
import { Section } from 'components/Section';
import { ZigzagCard } from 'components/ZigzagCard';
import { FC, Fragment } from 'react';
import { BuilderPageFrontMaster, SmartSection } from 'types/Builder';
import { StickyScroll } from 'components/StickyScroll';
import { PlansComparison1 } from 'templates/BuilderPage/PlansComparison1';
import { useLocation } from 'react-use';
import { CTASection } from './CTASection';
import { CollapseSection } from './CollapseSection';
import { CompareBuilder } from './CompareBuilder';
import { SectionContactForm } from './ContactForm';
import { CostComparison } from './CostComparison';
import { Features } from './Features';
import { FeaturesGrid } from './FeaturesGrid';
import { Hero } from './Hero';
import { PlanComparison } from './PlanComparison';
import { Plans } from './Plans';
import { Supports } from './Supports';
import { Theme } from './Theme';
import { Reviews } from './Reviews';
import { CTASection2 } from './CTASection2';
import { DemoTemplates } from './DemoTemplates';

export const BuilderPageTemplate: FC<BuilderPageFrontMaster> = ({ sections }) => {
  const location = useLocation();
  const stickyScrollDisabled = ['/notifications', '/pricing-for-veda-builder', '/install'].includes(location.pathname as string);
  const renderSection = (section: SmartSection) => {
    switch (section.type) {
      case 'hero':
        return <Hero {...section} />;
      case 'features':
        return <Features {...section} />;
      case 'themes':
        return <Theme {...section} />;
      case 'zigzag':
        return (
          <Section backgroundColor={section.backgroundColor} backgroundImage={section.backgroundImage}>
            <ZigzagCard {...section.zigzagContent} />
          </Section>
        );
      case 'supports':
        return <Supports {...section} />;
      case 'plans':
        return <Plans {...section} />;
      case 'collapse':
        return <CollapseSection {...section} />;
      case 'planComparison':
        return <PlanComparison {...section} />;
      case 'contactForm':
        return <SectionContactForm {...section} />;
      case 'featuresGrid':
        return <FeaturesGrid {...section} />;
      case 'compareBuilder':
        return <CompareBuilder {...section} />;
      case 'ctaSection':
        return <CTASection {...section} />;
      case 'costComparison':
        return <CostComparison {...section} />;
      case 'reviews':
        return <Reviews {...section} />;
      case 'ctaSection2':
        return <CTASection2 {...section} />;
      case 'planComparison1':
        return <PlansComparison1 {...section} />;
      case 'demoTemplates':
        return <DemoTemplates {...section} />;
      case 'richtext':
        return (
          section.enable && (
            <Section>
              <div className="maw:700px m:auto veda-rich-text">
                <Markdown>{section.richtextContent}</Markdown>
              </div>
            </Section>
          )
        );
      default:
        return null;
    }
  };

  return (
    <>
      {sections.filter(item => item.enable).length > 3 && !stickyScrollDisabled && <StickyScroll sections={sections} />}
      {sections.map((section, index) => {
        if (!section.enable) {
          return null;
        }
        return <Fragment key={index}>{renderSection(section)}</Fragment>;
      })}
    </>
  );
};
