import { CSSProperties, FC } from 'react';

export interface CompareBuilderCardProps {
  index: number;
  title: string;
  description: string;
  buttonText?: string;
  onButtonClick?: () => void;
  containerStyle?: CSSProperties;
}

export const CompareBuilderCard: FC<CompareBuilderCardProps> = ({ title, description, buttonText, index, onButtonClick, containerStyle }) => {
  return (
    <div className={`ta:center ${index % 2 !== 0 ? 'bdl:1px_solid_#3D2E7C@sm' : ''}`} style={containerStyle}>
      <div className="pt:pfs(20px,30px) pb:pfs(20px,30px) pl:pfs(10px,95px) pr:pfs(10px,95px)">
        <h3 className="mb:22px fz:pfs(20px,32px) fw:700 c:#EFF0FF">{title}</h3>
        <p className="mb:30px maw:420px fz:16px c:#D1D1F7">{description}</p>
        {!!buttonText && (
          <div
            className="d:inline-flex ai:center cg:5px p:9px_18px fz:11px fw:700 c:#D1D1F7 tt:uppercase bgc:#3D2E7C bdrs:9999px cur:pointer"
            onClick={onButtonClick}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.30957 0.788837C9.40806 0.887363 9.46339 1.02097 9.46339 1.16029C9.46339 1.2996 9.40806 1.43322 9.30957 1.53174L3.53026 7.31105C3.43173 7.40955 3.29812 7.46488 3.15881 7.46488C3.01949 7.46488 2.88588 7.40955 2.78735 7.31105L0.160396 4.68409C0.110216 4.63562 0.0701901 4.57765 0.0426549 4.51355C0.0151196 4.44945 0.000626043 4.38051 1.98371e-05 4.31075C-0.000586368 4.24099 0.012707 4.1718 0.0391241 4.10723C0.0655413 4.04267 0.104553 3.98401 0.153884 3.93467C0.203214 3.88534 0.261875 3.84633 0.326444 3.81992C0.391012 3.7935 0.460196 3.7802 0.529957 3.78081C0.599718 3.78142 0.66866 3.79591 0.73276 3.82345C0.79686 3.85098 0.854834 3.89101 0.9033 3.94119L3.15881 6.19669L8.56666 0.788837C8.66519 0.690342 8.7988 0.63501 8.93811 0.63501C9.07743 0.63501 9.21104 0.690342 9.30957 0.788837Z"
                fill="var(--color-gray1)"
              />
            </svg>
            {buttonText}
          </div>
        )}
      </div>
    </div>
  );
};
