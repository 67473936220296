import { ReviewCard } from 'components/ReviewCard';
import { Section } from 'components/Section';
import { Title } from 'components/Title';
import { navigate } from 'gatsby';
import { FC } from 'react';
import { useLocation } from 'react-use';
import { SectionReviews } from 'types/Builder';

export const Reviews: FC<SectionReviews> = ({
  heading,
  subtitle,
  description,
  decorate,
  buttonText,
  link,
  reviewContent,
  backgroundImage,
  backgroundColor,
}) => {
  const location = useLocation();
  const isBuilder = ['/pricing-for-veda-builder'].includes(location.pathname as string);

  const applyOverlayMask = (event: React.PointerEvent) => {
    const el = event.currentTarget as HTMLElement;
    const overlay = el.querySelector('.overlay') as HTMLElement;
    const x = event.pageX - el.offsetLeft;
    overlay.setAttribute('style', `left: ${x}px;`);
  };
  return (
    <Section className="pos:relative pt:pfs(40px,150px)!" backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <Title
        containerClass="mb:60px@sm!"
        title={heading}
        titleClass="fz:pfs(35px,58px) fw:500! lh:1.2"
        subTitle={subtitle}
        subTitleClass="mb:10px! fz:pfs(16px,28px) fw:300! c:color-dark"
        text={description}
        decorate={decorate}
      />
      <div className="container maw:100%@sm mah:950px">
        <div className="mt:-30px ml:-120px@sm mr:-120px@sm colmc:5 colmc:3@+md colmc:1@+sm! cg:30px">
          {reviewContent.map((review, index) => (
            <div key={index} className="d:inline-block w:100% mt:30px">
              <ReviewCard {...review} />
            </div>
          ))}
        </div>
      </div>
      <div
        className={`pos:absolute p:160px_0_40px_0 w:100% ${isBuilder ? 'b:35px' : 'b:0'}`}
        style={{ background: 'linear-gradient(181deg, rgba(255, 255, 255, 0.00) -11.33%, #FFF 88.91%)' }}
      >
        <div
          className="pos:relative p:17px_30px p:10px@+sm m:auto w:fit-content fz:pfs(13px,18px) fw:700 c:color-light bdrs:10px bxsh:0px_0px_10px_0px_#C4E6FF cur:pointer bgc:#079BFF ov:hidden"
          onPointerMove={applyOverlayMask}
          onClick={() => {
            if (link.includes('http')) {
              window.open(link, '_blank');
            } else {
              navigate(link);
            }
          }}
        >
          <div className="pos:relative w:fit-content d:flex jc:center ai:center cg:7px z:2">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
              <g clipPath="url(#clip0_1951_955)">
                <path
                  d="M9.42868 6.89084C9.91296 6.78818 10.2912 6.4264 10.3985 5.96318L11.5306 1.0914C11.8271 -0.184601 13.7284 -0.184601 14.0248 1.0914L15.157 5.96318C15.2643 6.4264 15.6425 6.78818 16.1268 6.89084L21.22 7.97373C22.554 8.25729 22.554 10.076 21.22 10.3595L16.1268 11.4424C15.6425 11.5451 15.2643 11.9068 15.157 12.3701L14.0248 17.2418C13.7284 18.5178 11.8271 18.5178 11.5306 17.2418L10.3985 12.3701C10.2912 11.9068 9.91296 11.5451 9.42868 11.4424L4.33546 10.3595C3.00146 10.076 3.00146 8.25729 4.33546 7.97373L9.42868 6.89084Z"
                  fill="white"
                />
                <path
                  d="M2.63391 16.8911C3.11819 16.7884 3.49641 16.4266 3.60375 15.9634L3.95002 14.4723C4.07397 13.937 4.8713 13.937 4.99525 14.4723L5.34152 15.9634C5.44886 16.4266 5.82708 16.7884 6.31136 16.8911L7.87025 17.2223C8.42991 17.3409 8.42991 18.1035 7.87025 18.2221L6.31136 18.5533C5.82708 18.656 5.44886 19.0177 5.34152 19.481L4.99525 20.9721C4.8713 21.5074 4.07397 21.5074 3.95002 20.9721L3.60375 19.481C3.49641 19.0177 3.11819 18.656 2.63391 18.5533L1.07502 18.2221C0.515357 18.1035 0.515357 17.3409 1.07502 17.2223L2.63391 16.8911Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1951_955">
                  <rect width="23" height="22" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {buttonText}
          </div>
          <div className="overlay pos:absolute t:0 l:100% trf:translateX(-50%) w:40% h:120% bgc:#a022fd fil:blur(25px)"></div>
        </div>
      </div>
    </Section>
  );
};
