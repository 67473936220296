import classNames from 'classnames';
import { Markdown } from 'components/Markdown/Markdown';
import { PricingPopupSupport, usePricingPopupStatic, usePricingPopupSupport } from 'components/PricingPopupSupport/PricingPopupSupport';
import { Section } from 'components/Section';
import { Title } from 'components/Title';
import { FC } from 'react';
import { SectionCostComparison } from 'types/Builder';
import { reactNodeToString } from 'utils/reactNodeToString';
import * as styles from './CostComparison.module.scss';

export const CostComparison: FC<SectionCostComparison> = ({ heading, description, costHeader, costBody, backgroundImage, backgroundColor }) => {
  const { contentRef, getLiProps } = usePricingPopupSupport();
  const popUplist = usePricingPopupStatic();
  return (
    <Section className="pos:relative" backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <div
        className="pos:absolute t:12% l:-5% w:850px h:773px bdrs:850px op:0.15 fil:blur(365px) trf:rotate(-165deg) z:-1"
        style={{ mixBlendMode: 'multiply', background: 'linear-gradient(141deg, var(--color-primary) -7.7%, var(--color-secondary) 107.88%)' }}
      ></div>
      <div
        className="pos:absolute b:-12% r:-5% w:976px h:887px bdrs:967px bgc:color-tertiary op:0.15 fil:blur(379px) trf:rotate(-165deg) z:-1"
        style={{ mixBlendMode: 'multiply' }}
      ></div>
      <div className="container">
        <Title title={heading} titleClass="fz:pfs(35px,58px) fw:500!" text={description} textClass="fw:300!" />
        <div className={classNames(styles.table, 'mt:pfs(30px,134px)! ov:hidden@+sm')}>
          <div className="pos:sticky l:0 t:0px w:100% z:2 d:none@+sm">
            <div className="d:flex w:100% bgc:color-gray2 bdrstl:10px bdrstend:10px">
              <div className="w:42%"></div>
              <div className="pos:relative p:26px_0 d:flex fld:column jc:center w:29% bdl:1px_solid_color-gray1 ta:center">
                <div
                  className="pos:absolute b:100% l:0 p:15px_0 w:100% d:flex jc:center ai:flex-end fz:27px fw:600 c:color-dark bdrs:10px_10px_0_0"
                  style={{
                    background:
                      'linear-gradient(97deg, rgba(15, 151, 228, 0.20) -38.57%, rgba(215, 85, 226, 0.20) 85.69%, rgba(49, 58, 224, 0.20) 182.35%)',
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="79" height="43" viewBox="0 0 79 43" fill="none">
                    <path
                      d="M65.0039 0C71.7086 0 76.9491 4.0075 78.6445 5.77996L56.2183 27.9749C53.4483 30.8653 49.976 34.4486 45.8144 37.9165C42.5777 40.6908 37.3269 42.1888 33.5609 42.078C25.7002 41.8468 22.2322 38.8412 17.6083 34.4485C12.7802 29.6204 7.71679 24.094 4.48425 20.8618C7.05155 23.4245 12.576 28.6038 19.9203 28.4369C30.5554 28.1952 36.5665 20.3454 40.7281 16.1839C42.8451 14.0669 50.2072 6.01115 53.444 3.69917C58.1171 0.36121 63.1772 0 65.0039 0Z"
                      fill="#D755E2"
                    />
                    <path
                      d="M14.1407 11.5601C7.43594 11.5601 2.19545 15.1051 0.5 16.8776L11.3663 27.9751C14.1363 30.8656 17.6086 34.6798 21.7702 38.1478C24.7758 40.6525 29.7954 42.1889 33.5613 42.0782C41.422 41.847 44.89 39.3038 49.7452 34.4487C55.0365 29.3873 60.1058 24.0849 63.338 20.8557C60.7679 23.4191 54.7836 28.8358 47.4332 28.6687C36.7981 28.427 34.0237 23.3512 27.0878 16.6464C23.3191 13.0034 18.1929 11.5601 14.1407 11.5601Z"
                      fill="#3DB8FD"
                      style={{ mixBlendMode: 'multiply' }}
                    />
                  </svg>
                  <span className="lh:1">VedaBuilder</span>
                </div>
                <div
                  className={styles.tableHeader}
                  dangerouslySetInnerHTML={{
                    __html: costHeader.col1,
                  }}
                ></div>
              </div>
              <div
                className={classNames(styles.tableHeader, 'p:26px_0 d:flex fld:column jc:center w:29% bdl:1px_solid_color-gray1 ta:center')}
                dangerouslySetInnerHTML={{
                  __html: costHeader.col2,
                }}
              ></div>
            </div>
          </div>
          {costBody.map((item, index) => {
            if (!!item.groupHeading) {
              return (
                <div className="d:flex w:100% bdt:1px_solid_color-gray2" key={index}>
                  <div className="p:26px_30px p:15px_30px@+sm w:42% w:100%@+sm ta:center@+sm fz:21px fw:500 c:color-primary bgc:color-gray2@+sm">
                    {item.heading}
                  </div>
                  <div className="p:26px_30px w:29% ta:center d:none@+sm c:color-dark">{item.contentCol1}</div>
                  <div className="p:26px_30px w:29% ta:center d:none@+sm c:color-dark">{item.contentCol2}</div>
                </div>
              );
            } else {
              const isPopUp = (txt: string) => {
                return (
                  typeof txt === 'string' &&
                  popUplist.some(popup => {
                    return popup.listTitle.replace(/﻿/g, '').trim() === txt.trim();
                  })
                );
              };
              const itemContent = item.contentCol1.trim();
              const itemContent2 = item.contentCol2.trim();
              return (
                <div ref={contentRef} className="d:flex fld:column@+sm w:100% bdt:1px_solid_color-gray2" key={index}>
                  <div
                    {...getLiProps({
                      children: [`${item.heading}`],
                      className: 'p:20px_30px w:42% w:100%@+sm ta:center@+sm bdb:1px_solid_color-gray2@+sm fz:18px! c:color-dark',
                    })}
                  >
                    {item.heading}
                    {isPopUp(item.heading ?? '') ? (
                      <span className="pos:relative ml:7px w:15px">
                        <svg
                          className="pos:absolute t:50% l:0 trf:translateY(-50%)"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="15"
                          viewBox="0 0 14 15"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1614_39431)">
                            <mask
                              id="mask0_1614_39431"
                              style={{ maskType: 'luminance' }}
                              maskUnits="userSpaceOnUse"
                              x="0"
                              y="0"
                              width="14"
                              height="15"
                            >
                              <path d="M14 0.5H0V14.5H14V0.5Z" fill="white" />
                            </mask>
                            <g mask="url(#mask0_1614_39431)">
                              <path
                                d="M7 13.625C5.37555 13.625 3.81763 12.9797 2.66897 11.831C1.52031 10.6824 0.875 9.12445 0.875 7.5C0.875 5.87555 1.52031 4.31763 2.66897 3.16897C3.81763 2.02031 5.37555 1.375 7 1.375C8.62445 1.375 10.1824 2.02031 11.331 3.16897C12.4797 4.31763 13.125 5.87555 13.125 7.5C13.125 9.12445 12.4797 10.6824 11.331 11.831C10.1824 12.9797 8.62445 13.625 7 13.625ZM7 14.5C8.85651 14.5 10.637 13.7625 11.9497 12.4497C13.2625 11.137 14 9.35651 14 7.5C14 5.64348 13.2625 3.86301 11.9497 2.55025C10.637 1.2375 8.85651 0.5 7 0.5C5.14348 0.5 3.36301 1.2375 2.05025 2.55025C0.737498 3.86301 0 5.64348 0 7.5C0 9.35651 0.737498 11.137 2.05025 12.4497C3.36301 13.7625 5.14348 14.5 7 14.5Z"
                                fill="var(--color-primary)"
                              />
                              <path
                                d="M7.81371 6.2645L5.80996 6.51562L5.73821 6.84812L6.13196 6.92075C6.38921 6.982 6.43996 7.07475 6.38396 7.33113L5.73821 10.3656C5.56846 11.1505 5.83008 11.5197 6.44521 11.5197C6.92208 11.5197 7.47596 11.2992 7.72709 10.9965L7.80408 10.6325C7.62908 10.7865 7.37358 10.8477 7.20383 10.8477C6.96321 10.8477 6.87571 10.6789 6.93783 10.3814L7.81371 6.2645ZM7.87496 4.4375C7.87496 4.66956 7.78277 4.89212 7.61868 5.05622C7.45458 5.22031 7.23202 5.3125 6.99996 5.3125C6.7679 5.3125 6.54534 5.22031 6.38124 5.05622C6.21715 4.89212 6.12496 4.66956 6.12496 4.4375C6.12496 4.20544 6.21715 3.98288 6.38124 3.81878C6.54534 3.65469 6.7679 3.5625 6.99996 3.5625C7.23202 3.5625 7.45458 3.65469 7.61868 3.81878C7.78277 3.98288 7.87496 4.20544 7.87496 4.4375Z"
                                fill="var(--color-primary)"
                              />
                            </g>
                          </g>
                          <defs>
                            <clipPath id="clip0_1614_39431">
                              <rect width="14" height="14" fill="white" transform="translate(0 0.5)" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="d:flex p:20px_50px p:10px@+sm w:29% w:100%@+sm bdl:1px_solid_color-gray2@sm bdb:1px_solid_color-gray2@+sm ta:center">
                    <div
                      className={classNames(styles.tableHeader, 'w:50%@+sm d:none@sm')}
                      dangerouslySetInnerHTML={{
                        __html: costHeader.col1,
                      }}
                    ></div>
                    <div className="w:100% w:50%@+sm">
                      <Markdown
                        components={{
                          li: ({ node: _, ...props }) => {
                            const liText = reactNodeToString(props.children).trim();
                            const textLast = liText.includes('[last]');
                            if (/^\d\./g.test(liText)) {
                              const text = liText.replace(/^\d\./g, '').replace('[last]', '');
                              return <li>{text}</li>;
                            }
                            if (liText.replace('[last]', '') === 'no') {
                              return (
                                <li className={textLast ? styles.last : ''}>
                                  <i className="far fa-minus" />
                                </li>
                              );
                            }
                            if (liText.replace('[last]', '') === 'yes') {
                              return (
                                <li className={textLast ? styles.last : ''}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path
                                      d="M2 9.97245L6.63776 14.7551C8.82143 8.48571 10.6378 5.73469 14.7551 2"
                                      stroke="var(--color-quaternary)"
                                      strokeWidth="2.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </li>
                              );
                            }
                            if (textLast) {
                              const text = liText.replace('[last]', '');
                              return (
                                <li {...props} className={styles.last}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path
                                      d="M2 9.97245L6.63776 14.7551C8.82143 8.48571 10.6378 5.73469 14.7551 2"
                                      stroke="var(--color-quaternary)"
                                      strokeWidth="2.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>{' '}
                                  {text}
                                </li>
                              );
                            }
                            return (
                              <li {...props}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                  <path
                                    d="M2 9.97245L6.63776 14.7551C8.82143 8.48571 10.6378 5.73469 14.7551 2"
                                    stroke="var(--color-quaternary)"
                                    strokeWidth="2.6"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>{' '}
                                {liText}
                              </li>
                            );
                          },
                          p: ({ node: _, ...props }) => {
                            const liText = reactNodeToString(props.children).trim();
                            const textLast = liText.includes('[last]');
                            if (liText.replace('[last]', '') === 'no') {
                              return (
                                <span className={textLast ? styles.last : ''}>
                                  <i className="far fa-minus" />
                                </span>
                              );
                            }
                            if (liText.replace('[last]', '') === 'yes') {
                              return (
                                <span className={textLast ? styles.last : ''}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path
                                      d="M2 9.97245L6.63776 14.7551C8.82143 8.48571 10.6378 5.73469 14.7551 2"
                                      stroke="var(--color-quaternary)"
                                      strokeWidth="2.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                              );
                            }
                            if (textLast) {
                              const text = liText.replace('[last]', '');
                              return (
                                <span {...props} className={styles.last}>
                                  {text}
                                </span>
                              );
                            }
                            return <span {...props} />;
                          },
                        }}
                      >
                        {!!itemContent ? `${itemContent}[last]` : itemContent}
                      </Markdown>
                    </div>
                  </div>
                  <div className="d:flex p:20px_50px p:10px@+sm w:29% w:100%@+sm bdl:1px_solid_color-gray2@sm ta:center">
                    <div
                      className={classNames(styles.tableHeader, 'w:50%@+sm d:none@sm')}
                      dangerouslySetInnerHTML={{
                        __html: costHeader.col2,
                      }}
                    ></div>
                    <div className="w:100% w:50%@+sm">
                      <Markdown
                        components={{
                          li: ({ node: _, ...props }) => {
                            const liText = reactNodeToString(props.children).trim();
                            const textLast = liText.includes('[last]');
                            if (/^\d\./g.test(liText)) {
                              const text = liText.replace(/^\d\./g, '').replace('[last]', '');
                              return <li>{text}</li>;
                            }
                            if (liText.replace('[last]', '') === 'no') {
                              return (
                                <li className={textLast ? styles.last : ''}>
                                  <i className="far fa-minus" />
                                </li>
                              );
                            }
                            if (liText.replace('[last]', '') === 'yes') {
                              return (
                                <li className={textLast ? styles.last : ''}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path
                                      d="M2 9.97245L6.63776 14.7551C8.82143 8.48571 10.6378 5.73469 14.7551 2"
                                      stroke="var(--color-quaternary)"
                                      strokeWidth="2.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </li>
                              );
                            }
                            if (textLast) {
                              const text = liText.replace('[last]', '');
                              return (
                                <li {...props} className={styles.last}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path
                                      d="M2 9.97245L6.63776 14.7551C8.82143 8.48571 10.6378 5.73469 14.7551 2"
                                      stroke="var(--color-quaternary)"
                                      strokeWidth="2.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>{' '}
                                  {text}
                                </li>
                              );
                            }
                            return (
                              <li {...props}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                  <path
                                    d="M2 9.97245L6.63776 14.7551C8.82143 8.48571 10.6378 5.73469 14.7551 2"
                                    stroke="var(--color-quaternary)"
                                    strokeWidth="2.6"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>{' '}
                                {liText}
                              </li>
                            );
                          },
                          p: ({ node: _, ...props }) => {
                            const liText = reactNodeToString(props.children).trim();
                            const textLast = liText.includes('[last]');
                            if (liText.replace('[last]', '') === 'no') {
                              return (
                                <span className={textLast ? styles.last : ''}>
                                  <i className="far fa-minus" />
                                </span>
                              );
                            }
                            if (liText.replace('[last]', '') === 'yes') {
                              return (
                                <span className={textLast ? styles.last : ''}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                    <path
                                      d="M2 9.97245L6.63776 14.7551C8.82143 8.48571 10.6378 5.73469 14.7551 2"
                                      stroke="var(--color-quaternary)"
                                      strokeWidth="2.6"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                              );
                            }
                            if (textLast) {
                              const text = liText.replace('[last]', '');
                              return (
                                <span {...props} className={styles.last}>
                                  {text}
                                </span>
                              );
                            }
                            return <span {...props} />;
                          },
                        }}
                      >
                        {!!itemContent2 ? `${itemContent2}[last]` : itemContent2}
                      </Markdown>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <PricingPopupSupport />
    </Section>
  );
};
