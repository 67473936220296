import { GetStartedPopup } from 'components/GetStartedPopup';
import { FC, useEffect, useState } from 'react';
import { SmartSection } from 'types/Builder';

export interface CompareBuilderCardProps {
  sections: SmartSection[];
}

// interface IntersectionObserverEntry {
//   time: number;
//   rootBounds: DOMRectReadOnly | null;
//   boundingClientRect: DOMRectReadOnly;
//   intersectionRect: DOMRectReadOnly;
//   intersectionRatio: number;
//   target: Element;
//   isIntersecting: boolean;
// }

export const StickyScroll: FC<CompareBuilderCardProps> = ({ sections }) => {
  const [currentPosition, setPosition] = useState(0);
  const [visibleScroll, setVisibleScroll] = useState(false);

  useEffect(() => {
    const sectionEls = Array.from(document.querySelectorAll('section[data-id]'));
    const handleSticky = () => {
      if (window.scrollY > 90 && !visibleScroll) {
        setVisibleScroll(true);
      } else {
        setVisibleScroll(false);
      }
    };
    const handleScroll = () => {
      sectionEls.forEach(section => {
        if (section.getBoundingClientRect().top >= 40 && section.getBoundingClientRect().top <= window.innerHeight / 2) {
          const uniqueId = section.getAttribute('data-id');
          const currentIndex = sectionEls.findIndex(el => {
            const currentId = el.getAttribute('data-id');
            return currentId === uniqueId;
          });
          setPosition(() => currentIndex);
        }
      });
    };

    window.addEventListener('scroll', handleSticky);
    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('scroll', handleSticky);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToSection = (index: number) => {
    const sectionEls1 = document.querySelectorAll('section');
    const currentSection = sectionEls1[index];
    window.scrollTo({
      top: currentSection.getBoundingClientRect().top + window.scrollY,
      behavior: 'smooth',
    });
    setPosition(() => index);
  };

  return (
    <div
      className={`pos:fixed b:25px w:100% d:flex jc:center ai:center z:99999 trs:0.3s d:none@+sm ${
        visibleScroll ? 'trf:translateY(0)' : 'trf:translateY(150%)'
      }`}
    >
      <div className="w:fit-content p:12px d:flex ai:center jc:space-between cg:30px h:100% bgc:#0F0F36 bdrs:9px">
        <div className="d:flex ai:center h:100%">
          {sections.map((item, index) => {
            return (
              item.enable && (
                <div
                  key={index}
                  className={`pos:relative p:14px_30px d:flex ai:center h:100% fz:16px fw:500 c:color-light td:none cur:pointer tt:uppercase us:none trs:0.3s ${
                    currentPosition === index ? 'bgc:rgb(60_60_83_/_65%) bdrs:6px' : ''
                  }`}
                  onClick={() => scrollToSection(index)}
                >
                  {item.sectionName ? item.sectionName : `Section ${index}`}
                </div>
              )
            );
          })}
        </div>
        <GetStartedPopup
          style="style2"
          hasIcon={true}
          buttonText="Install Veda"
          buttonStyle={{
            padding: '13px 10px',
            boxShadow: `0px 0px 10px 0px rgba(85, 27, 228, 0.90)`,
            fontSize: '14px',
            fontWeight: 700,
            fontFamily: 'var(--font-tertiary)',
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            columnGap: '7px',
            borderRadius: '6px',
            border: '1px solid var(--color-light)',
          }}
        />
      </div>
    </div>
  );
};
