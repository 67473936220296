import { Section } from 'components/Section';
import { Title } from 'components/Title';
import { FC } from 'react';
import { SectionDemo } from 'types/Builder';

export const DemoTemplates: FC<SectionDemo> = ({ heading, subtitle, description, decorate, backgroundImage, backgroundColor }) => {
  return (
    <Section className="pos:relative" backgroundColor={backgroundColor} backgroundImage={backgroundImage}>
      <Title
        title={heading}
        titleClass="maw:728px m:auto fz:pfs(35px,58px)"
        subTitle={subtitle}
        text={description}
        textClass="maw:652px fz:pfs(16px,20px) m:auto"
        decorate={decorate}
      />
      <iframe src="https://preview.vedabuilder.com/" className="mt:40px w:100% h:100vh bd:none bxsh:0px_2px_16.7px_0px_rgba(0,0,0,0.10)"></iframe>
    </Section>
  );
};
